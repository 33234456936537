<template>
  <div class="container py-3">
    <div class="mt-4" v-if="loading">
      <loader></loader>
    </div>
    <div class="company-pages row mt-2 mb-4" v-else>
      <div class="col-sm-12">
        <h4 class="title mb-5 ng-cloak">{{ data.title }}</h4>
      </div>
      <div class="col-sm-12" v-if="data.htmlContent">
        <p class="info-text" v-html="htmlContent"></p>
      </div>
    </div>
    <template v-if="data.hasChildPages">
      <div class="flex-row row flex-wrap mt-5">
        <div
          class="pointer mb-5 col-12 col-sm-6 col-lg-4 company-page-images"
          v-for="item in data.childPages"
          :key="item.id"
          @click="navigate(item)"
        >
          <figure class="mb-0 blur img-container embed-responsive embed-responsive-16by9">
            <img class="img-fluid embed-responsive-item" :src="item.imageURL" width="100%" />
            <div
              class="panel-caption"
              v-if="item.label && item.label.length"
              :style="{
                'background-color': style.companyPageImageCaptionBackgroundColor
                  ? style.companyPageImageCaptionBackgroundColor
                  : style.colorPrimaryButton,
                color: style.companyPageImageCaptionTextColor
                  ? style.companyPageImageCaptionTextColor
                  : style.colorPrimaryButtonText
              }"
            >
              <p class="subtitle">{{ item.label }}</p>
            </div>
          </figure>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  created() {
    this.$http.get(`dashboard/companypages/${this.$route.params.id}`).then(
      (response) => {
        this.data = response.data;
        this.loading = false;
      },
      (err) => {
        this.$router.push({ path: '/dashboard' });
        this.loading = false;
      }
    );
  },
  computed: {
    ...mapGetters('settings', ['style']),
    htmlContent() {
      return this.$sanitize(this.data.htmlContent);
    }
  },
  data() {
    return {
      data: {},
      loading: true
    };
  },
  methods: {
    navigate(item) {
      if (item.formId) {
        this.$http.get(`dashboard/companypages/${this.$route.params.id}/items/${item.id}`).then(
          (response) => {
            if (response.data && (response.data.description || response.data.htmlContent)) {
              return this.$router.push({
                name: 'companyPageContent',
                params: { overview: this.$route.params.id, id: item.id }
              });
            }
            this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
          },
          (err) => {
            this.$router.push({ name: 'legacyForm', params: { id: item.formId } });
          }
        );
      } else {
        this.$router.push({
          name: 'companyPageContent',
          params: { overview: this.$route.params.id, id: item.id }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-caption:hover {
  opacity: 1 !important;
}
.panel-caption p.subtitle {
  margin: 0;
}
</style>
