var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container py-3"},[(_vm.loading)?_c('div',{staticClass:"mt-4"},[_c('loader')],1):_c('div',{staticClass:"company-pages row mt-2 mb-4"},[_c('div',{staticClass:"col-sm-12"},[_c('h4',{staticClass:"title mb-5 ng-cloak"},[_vm._v(_vm._s(_vm.data.title))])]),(_vm.data.htmlContent)?_c('div',{staticClass:"col-sm-12"},[_c('p',{staticClass:"info-text",domProps:{"innerHTML":_vm._s(_vm.htmlContent)}})]):_vm._e()]),(_vm.data.hasChildPages)?[_c('div',{staticClass:"flex-row row flex-wrap mt-5"},_vm._l((_vm.data.childPages),function(item){return _c('div',{key:item.id,staticClass:"pointer mb-5 col-12 col-sm-6 col-lg-4 company-page-images",on:{"click":function($event){return _vm.navigate(item)}}},[_c('figure',{staticClass:"mb-0 blur img-container embed-responsive embed-responsive-16by9"},[_c('img',{staticClass:"img-fluid embed-responsive-item",attrs:{"src":item.imageURL,"width":"100%"}}),(item.label && item.label.length)?_c('div',{staticClass:"panel-caption",style:({
              'background-color': _vm.style.companyPageImageCaptionBackgroundColor
                ? _vm.style.companyPageImageCaptionBackgroundColor
                : _vm.style.colorPrimaryButton,
              color: _vm.style.companyPageImageCaptionTextColor
                ? _vm.style.companyPageImageCaptionTextColor
                : _vm.style.colorPrimaryButtonText
            })},[_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(item.label))])]):_vm._e()])])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }